import { For, onMount, Show } from "solid-js";
import { editWhiteIcon, HubbleFullSvg } from "~/assets/assets";
import { PhosphorIcon } from "~/widgets/icons";
import { HowItWorksModal } from "../modals/how_it_works_modal";
import { BorderGradient } from "~/components/gifting/gift_card";
import { openIntercom } from "~/utils/third_party/intercom";
import { useNavigate } from "@solidjs/router";
import { createStore } from "solid-js/store";
import { useSmartGreeting } from "~/context/smart_greeting_context";
import { useModal } from "~/components/modal";
import { ConfirmationsModal } from "../modals/confirmation_modal";
import { SmartGCFAQsModal } from "../modals/faqs_modal";

interface TopUpDetails {
  showAddAllDetails: boolean;
  showHowItWorks: boolean;
  showFAQs: boolean;
  error: string;
  isWishMessageFocused: boolean;
  wishMessageError: boolean;
}

export const TopUpComponent = (props: { giftingKey: string }) => {
  const navigate = useNavigate();
  const {
    smartGCDetails,
    setSmartGCDetails,
    getSmartGCDetailsFromLocalPostPreview,
    setSmartGCDetailsToLocalForPreview,
  } = useSmartGreeting();

  const [topUpDetails, setTopUpDetails] = createStore<TopUpDetails>({
    showAddAllDetails: false,
    showFAQs: false,
    showHowItWorks: false,
    error: "",
    isWishMessageFocused: false,
    wishMessageError: false,
  });

  const { openModal } = useModal()!;

  let topUpCardRef: HTMLDivElement | undefined;
  let inputBudgetRef: HTMLInputElement | undefined;
  let inputNameRef: HTMLInputElement | undefined;
  let wishMessageRef: HTMLTextAreaElement | undefined;

  function handleEditClick() {
    if (inputBudgetRef) {
      inputBudgetRef.focus();
    }
  }

  function handleNameEditClick() {
    if (inputNameRef) {
      inputNameRef.focus();
    }
  }

  function handleWishMessageEditClick() {
    if (wishMessageRef) {
      wishMessageRef.focus();
    }
  }

  onMount(() => {
    getSmartGCDetailsFromLocalPostPreview(smartGCDetails.curationId!);

    if (topUpCardRef) {
      topUpCardRef.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });

  function handleBudgetChange(event: InputEvent) {
    const inputValue = (event.target as HTMLInputElement).value;
    const valueWithoutSymbol = inputValue.replace("₹", "").trim();
    const value = +valueWithoutSymbol || 0;

    if (value >= 0) {
      if (value < 500 || value > 50000) {
        setTopUpDetails("error", "Amount should be between ₹500 - ₹50,000");
      } else {
        setTopUpDetails("error", "");
      }
      setSmartGCDetails("budget", value);
    }
  }

  const handleWishMessageChange = (event: InputEvent) => {
    const inputValue = (event.target as HTMLInputElement).value;
    const formattedText = inputValue.slice(0, 200);
    setSmartGCDetails("wishMessage", formattedText);
    setTopUpDetails("wishMessageError", formattedText.length >= 200);
  };

  function handleConfirmationModal() {
    if (
      !smartGCDetails.budget ||
      !smartGCDetails.senderName ||
      !smartGCDetails.brandDetails?.length ||
      (smartGCDetails.config.wishMessageEnabled &&
        !smartGCDetails.wishMessage?.trim())
    ) {
      setTopUpDetails("showAddAllDetails", true);

      setTimeout(() => {
        setTopUpDetails("showAddAllDetails", false);
      }, 3000);
    } else {
      openModal(
        () => (
          <ConfirmationsModal
            giftingKey={props.giftingKey}
            smartGCDetails={smartGCDetails}
            setSmartGCDetailsToLocal={() => {
              setSmartGCDetailsToLocalForPreview();
            }}
          />
        ),
        "dark",
        "!bg-black"
      );
    }
  }

  function handleSelectBrands() {
    if (
      smartGCDetails.brandDetails === null ||
      smartGCDetails.brandDetails?.length === 0
    ) {
      setSmartGCDetailsToLocalForPreview();
      navigate(`/smart-gc/create/${props.giftingKey}/select-brands`);
    }
  }

  return (
    <>
      <div class="no-scrollbar flex w-full flex-col items-center justify-start  ">
        <div class="no-scrollbar flex h-full w-full flex-col items-center  pb-[100px]">
          <div class="h-40 w-full p-4">
            <Show when={smartGCDetails.senderCardImageUrl}>
              <img src={smartGCDetails.senderCardImageUrl!} alt="" />
            </Show>
          </div>
          <div ref={topUpCardRef} class="w-full px-3">
            <div class={`h-14 transition-all duration-300`}></div>
            <BorderGradient
              className="bg-black"
              contentStyle={{
                "background-image":
                  "radial-gradient( #ffbc57 , rgba(0, 0, 0, 0) 80%)",
              }}
            >
              {buildTopUpCard()}
            </BorderGradient>
          </div>

          {buildCardDetails()}
        </div>
        {buildBottomCta()}
      </div>
      <Show when={topUpDetails.showHowItWorks}>
        <HowItWorksModal
          wishMessageEnabled={() => smartGCDetails.config.wishMessageEnabled!}
          closeModal={() => {
            setTopUpDetails("showHowItWorks", false);
          }}
          openFAQs={() => {
            setTopUpDetails("showHowItWorks", false);
            setTopUpDetails("showFAQs", true);
          }}
        />
      </Show>
      <Show when={topUpDetails.showFAQs}>
        <SmartGCFAQsModal
          closeModal={() => {
            setTopUpDetails("showFAQs", false);
          }}
        />
      </Show>
    </>
  );

  function buildTopUpCard() {
    return (
      <div class="relative min-h-[590px] w-full ">
        <div class=" flex min-h-[590px] w-full  min-w-[342px] flex-col items-center justify-center gap-8 rounded-[18px] border border-[#212427] bg-[#0D0D0D] px-[0.6rem] py-4 pt-0  text-white [box-shadow:0px_-12px_24px_0px_rgba(0,_0,_0,_0.20)]">
          <div>
            <p class="py-5 font-jakartaSans text-smallBold uppercase tracking-[1.1px] ">
              top up smart greeting card
            </p>
            <div class="h-px w-full bg-[linear-gradient(90deg,_#030202_0%,_#39585C_50.55%,_#030202_100%)]"></div>
          </div>
          <div class="flex w-full flex-col items-center justify-center gap-4">
            <p class="font-jakartaSans text-h3">Gift balance</p>
            <div class="relative h-14 w-[319px]">
              <input
                type="text"
                ref={inputBudgetRef}
                placeholder="₹0"
                value={
                  smartGCDetails.budget !== null
                    ? `₹${smartGCDetails.budget}`
                    : ""
                }
                onInput={handleBudgetChange}
                inputmode="numeric"
                class="w-[319px] rounded-xl border-2 border-basePrimaryDark bg-[#030202] px-3 py-2.5 text-center font-jakartaSans text-h3 placeholder:text-h3 placeholder:text-textNormal focus:border-white"
              />
              <Show when={!smartGCDetails.config.wishMessageEnabled}>
                <div
                  class="absolute right-3 top-[52%] flex h-[34px] w-[34px] -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-white p-[5px]"
                  onClick={handleEditClick}
                >
                  <img src={editWhiteIcon} class="h-7 w-7" alt="" />
                </div>
              </Show>
            </div>
            <Show
              when={topUpDetails.error}
              fallback={
                <p class="max-w-[319px] text-center text-medium text-textNormal">
                  Receiver can use the gift balance to shop on one or more
                  brands you select below, the way they like.
                </p>
              }
            >
              <p class="h-[36.4px] max-w-[319px] text-center text-medium text-errorDark">
                {topUpDetails.error}
              </p>
            </Show>
          </div>
          <div class="flex w-full flex-col items-center justify-center gap-4">
            <p class="font-jakartaSans text-h3">Brands</p>
            <div
              class={`flex w-[319px] ${(smartGCDetails.brandDetails?.length ?? 0 > 0) ? "h-[174px] pb-3" : "h-[121px]"} flex-col items-center justify-center gap-3 rounded-xl border-2 border-basePrimaryDark bg-[#030202] p-2`}
              onClick={handleSelectBrands}
            >
              <Show
                when={smartGCDetails.brandDetails?.length ?? 0 > 0}
                fallback={
                  <div class="relative h-full w-full items-center justify-center rounded-lg border border-dashed border-basePrimaryDark">
                    <div class="absolute left-2/4 top-2/4 flex h-[34px] w-[34px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border border-white">
                      <PhosphorIcon
                        name={"plus"}
                        class={"text-white"}
                        fontSize={24}
                        size="bold"
                      />
                    </div>
                  </div>
                }
              >
                <div class="flex gap-2">
                  <For each={smartGCDetails.brandDetails}>
                    {(brand) => {
                      return (
                        <div>
                          <img
                            src={brand.logoUrl}
                            alt={brand.name}
                            class="h-[108px] w-[97.32px]  rounded-[18px] border border-[#ffffff45]"
                          />
                        </div>
                      );
                    }}
                  </For>
                </div>
                <div class="flex items-center justify-center">
                  <div
                    class="flex h-[34px] w-[34px] cursor-pointer items-center justify-center rounded-full border border-white p-[5px]"
                    onClick={() => {
                      setSmartGCDetailsToLocalForPreview();
                      navigate(
                        `/smart-gc/create/${props.giftingKey}/select-brands`
                      );
                    }}
                  >
                    <img src={editWhiteIcon} class="h-7 w-7" alt="" />
                  </div>
                </div>
              </Show>
            </div>
          </div>
          <div class="flex w-full flex-col items-center justify-center gap-4">
            <p class="font-jakartaSans text-h3">Your name</p>
            <div class="relative h-14 w-[319px]">
              <input
                type="text"
                maxLength={20}
                value={smartGCDetails.senderName ?? ""}
                ref={(el) => (inputNameRef = el)}
                onInput={(e) => {
                  setSmartGCDetails("senderName", e.target.value);
                }}
                class="flex w-[319px] items-center justify-center rounded-xl border-2 border-basePrimaryDark bg-[#030202] px-3 py-2.5 text-center font-jakartaSans  text-white placeholder:text-f16Bold focus:border-white"
                classList={{
                  "text-normal font-medium":
                    smartGCDetails.config.wishMessageEnabled!,
                  "text-h3": !smartGCDetails.config.wishMessageEnabled,
                }}
              />
              <Show when={!smartGCDetails.senderName}>
                <p
                  onClick={handleNameEditClick}
                  class="absolute left-2/4 -translate-x-1/2 -translate-y-1/2 text-textNormal"
                  classList={{
                    "text-normal font-medium  top-[40%]":
                      smartGCDetails.config.wishMessageEnabled!,
                    "text-f16Bold top-2/4":
                      !smartGCDetails.config.wishMessageEnabled,
                  }}
                >
                  Enter name
                </p>
              </Show>
              <Show when={!smartGCDetails.config.wishMessageEnabled}>
                <div
                  class="absolute right-3 top-[52%] flex h-[34px] w-[34px] -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-white p-[5px]"
                  onClick={handleNameEditClick}
                >
                  <img src={editWhiteIcon} class="h-7 w-7" alt="" />
                </div>
              </Show>
            </div>
          </div>
          <Show when={smartGCDetails.config.wishMessageEnabled}>
            <div class="flex w-full flex-col items-center justify-center">
              <p class="mb-4 font-jakartaSans text-h3">Your message</p>
              <div class="relative h-[120px] w-[319px]">
                <textarea
                  ref={(el) => (wishMessageRef = el)}
                  value={smartGCDetails.wishMessage ?? ""}
                  maxLength={200}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  onFocusIn={() =>
                    setTopUpDetails("isWishMessageFocused", true)
                  }
                  onFocusOut={() =>
                    setTopUpDetails("isWishMessageFocused", false)
                  }
                  onInput={handleWishMessageChange}
                  class="flex h-[120px] w-[319px] resize-none items-center justify-center rounded-xl border-2  bg-[#030202] px-3 py-2.5 text-center font-jakartaSans text-normal font-medium  text-white"
                  classList={{
                    "border-[#E03616] focus:border-[#E03616]":
                      topUpDetails.wishMessageError,
                    "border-basePrimaryDark focus:border-white":
                      !topUpDetails.wishMessageError,
                  }}
                  style={{
                    "vertical-align": "middle",
                    "padding-top": `${
                      smartGCDetails.wishMessage
                        ? `${Math.max(10, 50 - (smartGCDetails.wishMessage.length / 30) * 10)}px`
                        : "50px"
                    }`,
                  }}
                />
                <Show
                  when={
                    !smartGCDetails.wishMessage &&
                    !topUpDetails.isWishMessageFocused
                  }
                >
                  <p
                    onClick={handleWishMessageEditClick}
                    class="absolute left-2/4 top-2/4 -translate-x-1/2 -translate-y-1/2 text-normal font-medium text-textNormal"
                  >
                    Write a message
                  </p>
                </Show>
              </div>
              <p
                class="mt-2 w-[319px] text-end text-[10px] font-normal text-textNormal"
                classList={{ "text-[#E03616]": topUpDetails.wishMessageError }}
              >
                {smartGCDetails.wishMessage?.length ?? 0}/200
              </p>
            </div>
          </Show>
        </div>
      </div>
    );
  }

  function buildCardDetails() {
    return (
      <div class={` mt-6 flex w-full flex-col gap-8 px-5`}>
        <div class="flex w-full flex-col items-center">
          <div
            class="flex h-[60px] w-full cursor-pointer items-center justify-between gap-2 border-b border-basePrimaryMedium"
            onClick={() => setTopUpDetails("showHowItWorks", true)}
          >
            <p class="font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal">
              How it works?
            </p>
            <PhosphorIcon
              name={"caret-right"}
              class={"text-textNormal"}
              fontSize={14}
              size="bold"
            />
          </div>
          <div
            class="flex h-[60px] w-full cursor-pointer items-center justify-between gap-2 border-b border-basePrimaryMedium"
            onClick={() => setTopUpDetails("showFAQs", true)}
          >
            <p class="font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal">
              FAQs
            </p>
            <PhosphorIcon
              name={"caret-right"}
              class={"text-textNormal"}
              fontSize={14}
              size="bold"
            />
          </div>
          <div
            class="flex h-[60px] w-full cursor-pointer items-center justify-between gap-2 border-b border-basePrimaryMedium"
            onClick={() => {
              openIntercom();
            }}
          >
            <p class="font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal">
              Facing issues? Contact Support
            </p>
            <PhosphorIcon
              name={"caret-right"}
              class={"text-textNormal"}
              fontSize={14}
              size="bold"
            />
          </div>
        </div>
        <div class="flex flex-col items-center gap-1">
          <p class="text-center text-[10px] font-medium uppercase tracking-[1.8px] text-baseSecondaryMedium">
            Powered by
          </p>
          <HubbleFullSvg class="h-[17.6px] w-[81px]" fill="#7C7C7C" />
        </div>
      </div>
    );
  }

  function buildBottomCta() {
    return (
      <div class="fixed bottom-0 left-0 right-0 z-10">
        <Show when={topUpDetails.showAddAllDetails}>
          <div
            class="flex  items-center  justify-between bg-[#E0B827] py-2.5 pl-4 pr-2 "
            classList={{
              "h-10": !smartGCDetails.config.wishMessageEnabled,
              "h-14": smartGCDetails.config.wishMessageEnabled!,
            }}
          >
            <Show
              when={smartGCDetails.config.wishMessageEnabled}
              fallback={
                <p class="text-mediumBold text-basePrimaryDark">
                  Please add balance, brands & name to proceed
                </p>
              }
            >
              <p class="text-mediumBold text-basePrimaryDark">
                Please add balance, brands, name <br /> & message to proceed
              </p>
            </Show>
            <PhosphorIcon
              name={"x"}
              fontSize={24}
              class="cursor-pointer text-black"
              size="light"
              onClick={() => {
                setTopUpDetails("showAddAllDetails", false);
              }}
            ></PhosphorIcon>
          </div>
        </Show>
        <div
          class={`flex justify-center gap-3 border-t border-basePrimaryDark bg-black p-5`}
        >
          <div
            class="flex h-11 w-full items-center justify-center rounded-[41px] border border-baseSecondaryDark  px-3 text-buttonMedium"
            onClick={() => {
              if (
                !smartGCDetails.budget ||
                !smartGCDetails.senderName ||
                !smartGCDetails.brandDetails?.length ||
                (smartGCDetails.config.wishMessageEnabled &&
                  !smartGCDetails.wishMessage?.trim())
              ) {
                setTopUpDetails("showAddAllDetails", true);

                setTimeout(() => {
                  setTopUpDetails("showAddAllDetails", false);
                }, 3000);
              } else {
                setSmartGCDetailsToLocalForPreview();

                const state = {
                  senderName: smartGCDetails.senderName,
                  budget: smartGCDetails.budget,
                  brandDetails: smartGCDetails.brandDetails,
                  receiverCardImageUrl: smartGCDetails.receiverCardImageUrl,
                  receiverCardBackgroundColour:
                    smartGCDetails.receiverCardBackgroundColour,
                  ...(smartGCDetails.config.wishMessageEnabled && {
                    wishMessage: smartGCDetails.wishMessage,
                    revealMusicUrl: smartGCDetails.revealMusicUrl,
                    wishMessageBackgroundImageUrl:
                      smartGCDetails.wishMessageBackgroundImageUrl,
                  }),
                };

                const data = JSON.stringify(state);
                window.location.href =
                  "/smart-gc/reveal/" +
                  props.giftingKey +
                  `?isPreview=true&data=${encodeURIComponent(data)}`;
              }
            }}
          >
            <p class="text-nowrap text-buttonMedium text-white">
              Receiver’s preview
            </p>
          </div>
          <button
            class="flex h-11 w-full cursor-pointer items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
            onClick={handleConfirmationModal}
          >
            <p class="text-buttonMedium font-bold text-textDark">Continue</p>
          </button>
        </div>
      </div>
    );
  }
};
